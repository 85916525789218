.grid-container {
    display: grid;
    gap: 50px;
    grid-template-columns: auto auto;
  }
  .item1 {
    grid-column: 1 / span 3;
  }
  .item2 {
    grid-column: 2 / span 3;
  }