/* Icons SVG Sprite */
[class$="-icon"],
[class*="-icon "] {
  display: inline-block;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-image: url('../../images/icons/sprite/sprite.svg');
}

.hs-3-leaf-clover-black-icon {
  background-position: 0 0;
}

.hs-3-leaf-clover-blue-icon {
  background-position: -48px 0;
}

.hs-3-leaf-clover-grey-icon {
  background-position: -96px 0;
}

.hs-3-leaf-clover-white-icon {
  background-position: -144px 0;
}

.hs-api-black-icon {
  background-position: -192px 0;
}

.hs-api-blue-icon {
  background-position: -240px 0;
}

.hs-api-grey-icon {
  background-position: -288px 0;
}

.hs-api-white-icon {
  background-position: -336px 0;
}

.hs-app-black-icon {
  background-position: -384px 0;
}

.hs-app-blue-icon {
  background-position: -432px 0;
}

.hs-app-grey-icon {
  background-position: -480px 0;
}

.hs-app-white-icon {
  background-position: -528px 0;
}

.hs-bullet-black-icon {
  background-position: -576px 0;
}

.hs-bullet-blue-icon {
  background-position: -624px 0;
}

.hs-bullet-grey-icon {
  background-position: -672px 0;
}

.hs-bullet-white-icon {
  background-position: -720px 0;
}

.hs-deploy-black-icon {
  background-position: 0 -48px;
}

.hs-deploy-blue-icon {
  background-position: -48px -48px;
}

.hs-deploy-grey-icon {
  background-position: -96px -48px;
}

.hs-deploy-white-icon {
  background-position: -144px -48px;
}

.hs-design-black-icon {
  background-position: -192px -48px;
}

.hs-design-blue-icon {
  background-position: -240px -48px;
}

.hs-design-grey-icon {
  background-position: -288px -48px;
}

.hs-design-white-icon {
  background-position: -336px -48px;
}

.hs-develop-black-icon {
  background-position: -384px -48px;
}

.hs-develop-blue-icon {
  background-position: -432px -48px;
}

.hs-develop-grey-icon {
  background-position: -480px -48px;
}

.hs-develop-white-icon {
  background-position: -528px -48px;
}

.hs-discover-black-icon {
  background-position: -576px -48px;
}

.hs-discover-blue-icon {
  background-position: -624px -48px;
}

.hs-discover-grey-icon {
  background-position: -672px -48px;
}

.hs-discover-white-icon {
  background-position: -720px -48px;
}

.hs-discuss-black-icon {
  background-position: 0 -96px;
}

.hs-discuss-blue-icon {
  background-position: -48px -96px;
}

.hs-discuss-grey-icon {
  background-position: -96px -96px;
}

.hs-discuss-white-icon {
  background-position: -144px -96px;
}

.hs-envelope-black-icon {
  background-position: -192px -96px;
}

.hs-envelope-blue-icon {
  background-position: -240px -96px;
}

.hs-envelope-grey-icon {
  background-position: -288px -96px;
}

.hs-envelope-white-icon {
  background-position: -336px -96px;
}

.hs-flame-black-icon {
  background-position: -384px -96px;
}

.hs-flame-blue-icon {
  background-position: -432px -96px;
}

.hs-flame-grey-icon {
  background-position: -480px -96px;
}

.hs-flame-white-icon {
  background-position: -528px -96px;
}

.hs-home-hover-icon {
  background-position: -576px -96px;
}

.hs-home-icon {
  background-position: -624px -96px;
}

.hs-mail-black-icon {
  background-position: -672px -96px;
}

.hs-mail-blue-icon {
  background-position: -720px -96px;
}

.hs-mail-grey-icon {
  background-position: 0 -144px;
}

.hs-mail-white-icon {
  background-position: -48px -144px;
}

.hs-marker-black-icon {
  background-position: -96px -144px;
}

.hs-marker-blue-icon {
  background-position: -144px -144px;
}

.hs-marker-grey-icon {
  background-position: -192px -144px;
}

.hs-marker-white-icon {
  background-position: -240px -144px;
}

.hs-mobile-apps-black-icon {
  background-position: -288px -144px;
}

.hs-mobile-apps-blue-icon {
  background-position: -336px -144px;
}

.hs-mobile-apps-grey-icon {
  background-position: -384px -144px;
}

.hs-mobile-apps-white-icon {
  background-position: -432px -144px;
}

.hs-mobile-user-black-icon {
  background-position: -480px -144px;
}

.hs-mobile-user-blue-icon {
  background-position: -528px -144px;
}

.hs-mobile-user-grey-icon {
  background-position: -576px -144px;
}

.hs-mobile-user-white-icon {
  background-position: -624px -144px;
}

.hs-payment-black-icon {
  background-position: -672px -144px;
}

.hs-payment-blue-icon {
  background-position: -720px -144px;
}

.hs-payment-grey-icon {
  background-position: 0 -192px;
}

.hs-payment-white-icon {
  background-position: -48px -192px;
}

.hs-psd2html-black-icon {
  background-position: -96px -192px;
}

.hs-psd2html-blue-icon {
  background-position: -144px -192px;
}

.hs-psd2html-grey-icon {
  background-position: -192px -192px;
}

.hs-psd2html-white-icon {
  background-position: -240px -192px;
}

.hs-rwd-black-icon {
  background-position: -288px -192px;
}

.hs-rwd-blue-icon {
  background-position: -336px -192px;
}

.hs-rwd-grey-icon {
  background-position: -384px -192px;
}

.hs-rwd-white-icon {
  background-position: -432px -192px;
}

.hs-share-this-black-icon {
  background-position: -480px -192px;
}

.hs-share-this-blue-icon {
  background-position: -528px -192px;
}

.hs-share-this-grey-icon {
  background-position: -576px -192px;
}

.hs-share-this-white-icon {
  background-position: -624px -192px;
}

.hs-snowflake-black-icon {
  background-position: -672px -192px;
}

.hs-snowflake-blue-icon {
  background-position: -720px -192px;
}

.hs-snowflake-grey-icon {
  background-position: 0 -240px;
}

.hs-snowflake-white-icon {
  background-position: -48px -240px;
}

.hs-telephone-black-icon {
  background-position: -96px -240px;
}

.hs-telephone-blue-icon {
  background-position: -144px -240px;
}

.hs-telephone-grey-icon {
  background-position: -192px -240px;
}

.hs-telephone-white-icon {
  background-position: -240px -240px;
}

.hs-uxui-black-icon {
  background-position: -288px -240px;
}

.hs-uxui-blue-icon {
  background-position: -336px -240px;
}

.hs-uxui-white-icon {
  background-position: -384px -240px;
}

.hs-web-hosting-black-icon {
  background-position: -432px -240px;
}

.hs-web-hosting-blue-icon {
  background-position: -480px -240px;
}

.hs-web-hosting-grey-icon {
  background-position: -528px -240px;
}

.hs-web-hosting-white-icon {
  background-position: -576px -240px;
}

.hs-webdev-black-icon {
  background-position: -624px -240px;
}

.hs-webdev-blue-icon {
  background-position: -672px -240px;
}

.hs-webdev-grey-icon {
  background-position: -720px -240px;
}

.hs-webdev-white-icon {
  background-position: 0 -288px;
}

/* Icons SVG*/
[class$="-isvg"],
[class*="-isvg "] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

/* ==========================================
Single-colored icons can be modified like so:
.name-isvg {
  font-size: 32px;
  color: red;
}
========================================== */
.hs-step-icon {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  fill: rgba(0, 0, 0, 0.5);
  background-image: none;
}

.hs-home-icon.hs-f-home {
  //transform:scale(0.5);
  background-size: 376px 158px;
  width: 16px;
  height: 16px;
  background-position: -310px -46px;

  &:hover {
    background-position: -576px -96px;
    background-position: -286px -46px;
  }
}