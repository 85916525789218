//Animation
.hs-animation,
.hs-step-animation,
.hs-ks-animation,
.hs-cover-animation {
  opacity: 0;
}
.hs-animation.animated,
.hs-step-animation.animated,
.hs-ks-animation.animated,
.hs-cover-animation.animated {
  opacity: 1;
}
.hs-animate-infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.hs-animate-duration5 {
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
}
.hs-animate-duration60 {
  -webkit-animation-duration: 60s;
  animation-duration: 60s;
}
.hs-animate-duration2 {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
//Transition
.hs-fast-trans,
.hs-fast-trans:hover {
  -webkit-transition: all ease-out 0.5s;
  -moz-transition: all ease-out 0.5s;
  -o-transition: all ease-out 0.5s;
  transition: all ease-out 0.5s;
}
//Hover
.hs-hvr-underline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
}
.hs-hvr-underline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  bottom: 0;
  background: $hs-blue;
  height: 1px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hs-hvr-underline-from-left:hover:before,
.hs-hvr-underline-from-left:focus:before,
.hs-hvr-underline-from-left:active:before {
  right: 0;
}

/* Underline From Center */
.hs-hvr-underline-from-center {
  display: inline-block;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
}
.hs-hvr-underline-from-center:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 50%;
  right: 50%;
  bottom: 0;
  background: $hs-blue;
  height: 1px;
  -webkit-transition-property: left, right;
  transition-property: left, right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hs-hvr-underline-from-center:hover:before,
.hs-hvr-underline-from-center:focus:before,
.hs-hvr-underline-from-center:active:before {
  left: 0;
  right: 0;
}

/* Underline From Right */
.hs-hvr-underline-from-right {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
}
.hs-hvr-underline-from-right:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 100%;
  right: 0;
  bottom: 0;
  background: #f5f5f5;
  height: 1px;
  -webkit-transition-property: left;
  transition-property: left;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hs-hvr-underline-from-right:hover:before,
.hs-hvr-underline-from-right:focus:before,
.hs-hvr-underline-from-right:active:before {
  left: 0;
}

/* Overline From Left */
.hs-hvr-overline-from-left {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px transparent;
  position: relative;
  overflow: hidden;
}
.hs-hvr-overline-from-left:before {
  content: "";
  position: absolute;
  z-index: -1;
  left: 0;
  right: 100%;
  top: 0;
  background: $hs-blue;
  height: 4px;
  -webkit-transition-property: right;
  transition-property: right;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hs-hvr-overline-from-left:hover:before,
.hs-hvr-overline-from-left:focus:before,
.hs-hvr-overline-from-left:active:before {
  right: 0;
}
.hs-gradient {
  background: #f5f5f5;
  background: -webkit-linear-gradient(to left, #f5f5f5, #ffffff);
  background: linear-gradient(to left, #f5f5f5, #ffffff);
}
.hs-grayscale {
  filter: gray;
  filter: grayscale(1);
  -webkit-filter: grayscale(1);
}
.hs-brighten {
  opacity: 0.7;
  transition: opacity 0.25s ease-in-out;
  -moz-transition: opacity 0.25s ease-in-out;
  -webkit-transition: opacity 0.25s ease-in-out;
  &:hover {
    opacity: 0.9;
    transition: opacity 0.25s ease-in-out;
    -moz-transition: opacity 0.25s ease-in-out;
    -webkit-transition: opacity 0.25s ease-in-out;
  }
}
.hs-vertical-txt {
  transform: rotate(-90deg) translate(-100%, 0);
  transform-origin: 0 0;
  display: inline-block;
}

.hs-blue-separator {
    width: 130px;
    height: 5px;
    background-color: $hs-blue;
    display: inline-block;
    margin: 3rem 0;
  }