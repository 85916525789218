//Partials
@import "partials/reset";
@import "partials/base";
@import "partials/grid";

//Vendor
@import "partials/animate";
@import "partials/hs-common";
@import "partials/react-toggle";
//Icons
@import "partials/icons";

body {
  background-color: $background-color;
  color: $foreground-color;
  font-family: $font-stack3;
  font-size: 1rem;
  line-height: 1.5;
  transition: all 0.5s linear;
  &.dark{
    background-color: $background-color-dk;
    color: $foreground-color-dk;
  }
}

//Images filter
img:not(.hs-brighten):not(.hs-wt-icons),
video {
  filter: $image-filter;
}

body.dark {
  img.hs-client-icons {
    background-color: $image-background-color-dk;
  }
  img.hs-tech-logo {
    filter: invert(100%) sepia(0%) saturate(7494%) hue-rotate(219deg)
      brightness(100%) contrast(101%);
    &.hs-filter-cw {
      filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
        saturate(7494%) hue-rotate(219deg) brightness(100%) contrast(101%);
    }
    &.hs-filter-ccw {
      filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(219deg)
        brightness(100%) contrast(101%);
    }
  }
  img:not(.hs-brighten):not(.hs-wt-icons),
  video {
    filter: $image-filter-dk;
  }
}
// @media (prefers-color-scheme: dark) {
//   body {
//     background-color: $background-color-dk;
//     color: $foreground-color-dk;
//   }
// }
//Wrapper
.hs-wrapper {
  max-width: 1920px;
  width:95%;
  margin: 0 auto;
}

//Header
header {
  width: 100%;
  border-bottom: 1px solid $hs-grey-border1;
}
.hs-header-inner{
  display: flex;
  flex-flow:row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 0;
}
.hs-logo {
  a {
    width:250px;
    line-height: 0;
    display: flex;
  }
  img {
    width: 100%;
    height: auto;
  }
}

.hs-contact-top {
  display: flex;
  flex-flow:row wrap;
  a {
    display: flex;
  }
  .hs-mode-toggle {
    margin-left: 1.25rem;
    position: relative;
  }
}
.hs-contact-icon {
  background-image: url("../images/icons/sprite/sprite.svg");
  background-position: -240px -95px;
  width: 32px;
  height: 32px;
  font-size: 0;
  overflow: hidden;
  display: inline-block;
  &:hover {
    background-position: -192px -95px;
  }
}
//Cover Section
.hs-cover-area {
  background-image: url("../images/hs-cover-bgnd.webp");
  // @supports (background-image: url("../images/hs-cover-bgnd.webp")) {
  //   background-image: url("../images/hs-cover-bgnd.webp");
  // }
  background-position: 0 0;
  background-repeat: repeat-x;
  background-color: transparent;
  width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
  position: relative;
  .hs-cover-top {
    margin: 12.5rem 0 6.25rem 0;
    display: block;
    overflow: hidden;
  }
  .hs-caption2 {
    margin-bottom: 7.5rem;
  }
}

.hs-caption1 {
  display: block;
  font-family: $heading-font1;
  font-size: 4.5rem;
  line-height: 5rem;
  text-transform: uppercase;
  span{
    display: block;
  }
}
.hs-caption2 {
  font-family: $heading-font3;
  font-size: 4rem;
  line-height: 4.5rem;
  text-transform: uppercase;
  span{
    display: block;
  }
}

//Home Services Section

.hs-main-area {
  text-align: center;
  background-color: $hs-grey;
  overflow: hidden;
  border-top: 1px solid $hs-grey-border1;
  border-bottom: 1px solid $hs-grey-border1;
  padding: 7rem 0;
  .hs-btn1 {
    margin-top: 3rem;
  }
}
.hs-steps {
  margin-bottom: 6rem;
  font-family: $heading-font2;
  li {
    display: inline-block;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 2.5rem;
    line-height: 1;
    font-weight: bold;
    margin-right: 1.25rem;
    position: relative;
    padding-top: 0.438rem;
    .hs-step-txt {
      margin-right: 0.313rem;
    }
    &:hover .hs-step-icon {
      fill: $hs-blue;
    }
  }
  .hs-step-cnt {
    display: flex;
    align-items: flex-start;
    font-size: 1.125rem;
    color: rgba(0, 0, 0, 0.5);
    line-height: 1;
    margin-bottom: 5px;
  }
}
//Keys Services
.hs-keys-services{
.hs-key-service{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-size: 1.75rem;
  margin-bottom: 1rem;
}
.hs-key,.hs-service{
  display: flex;
  flex-flow:row wrap;
  justify-content: flex-start;
  align-items: center;
}

.hs-key{
  span:not(.hs-key-txt){
    margin-right: 1.25rem;
  }
}
.hs-service{
  flex-direction: row-reverse;
  span{
    margin-left: 1.25rem;
  }
}
}

.hs-clients-area,
.hs-testimonials-area {
  text-align: center;
  padding: 7rem 0;
  overflow: hidden;
  border-bottom: 1px solid $hs-grey-border1;
}
.hs-testimonials-area blockquote{
  max-width: 49%;
  margin: 0 auto;
}
blockquote {
  font-size: 1.75rem;
  line-height: normal;
  font-style: italic;
}
cite {
  font-style: normal;
  margin-top: 1.25rem;
  display: block;
}
.hs-read-more {
  margin-top: 1.25rem;
  display: inline-block;
  color: $hs-blue !important;
  cursor: pointer;
  &:hover {
    color: $hs-black !important;
  }
}

.hs-web-technologies-types,
.hs-common-contact-area {
  overflow: hidden;
  border-bottom: 1px solid $hs-grey-border1;
}
  .hs-half-block {
    width: 50%;
    float: left;
    text-align: center;
    .hs-caption1 {
      font-size: 2.25rem;
      line-height: 2.5rem;
    }
    .hs-blue-separator {
      width: 65px;
      margin: 1.5rem 0;
    }

    .hs-clients-list li,
    .hs-clients-list .bxslider div {
      display: inline-block;
      width: 50%;
      padding: 3px;
    }
    .hs-clients-list li img {
      max-width: 100%;
      height: auto;
    }
  }
  .hs-half-block-inner {
    padding: 3.5rem 2.5rem;
  }
  .hs-border-right {
    border-right: 1px solid $hs-grey-border1;
  }
  .hs-border-left {
    border-left: 1px solid $hs-grey-border1;
  }

.hs-work-area {
  padding: 3rem 0;
}
.hs-gallery-separator {
  height: 1px;
  width: 100%;
  background-color: $hs-grey-border1;
  margin: 3rem 0;
}
.hs-common-contact-area a.hs-read-more {
  color: #000;
}

.hs-common-contact-area {
  border-bottom: 0;
}

.hs-form-area {
  text-align: center;
  padding: 7rem 0;
  overflow: hidden;
  p {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 65px;
  }
}
.hs-form {
  input,
  textarea,
  select {
    border: 0;
    outline: 0 none;
    border-bottom: 1px solid $hs-grey-border2;
    width: 100%;
    padding: 10px 1.25rem;
    &:focus {
      border-bottom: 1px solid $hs-blue;
    }
  }
  input.has-error,
  textarea.has-error {
    border-bottom: 1px solid red;
  }
  .hs-field {
    overflow: hidden;
    margin-bottom: 1.25rem;
    width: 100%;
    span {
      color: red;
      text-align: left;
      width: 100%;
      display: block;
    }
  }
  .hs-select {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
    background-color: transparent;
    color: rgba(0, 0, 0, 0.5);
    appearance: none;
    border-radius: 0;
    background: url("../images/select_icon.svg") no-repeat right transparent;
  }

  .hs-field-half {
    width: 48%;
    float: left;
  }
  .hs-field-half-right {
    margin-left: 4%;
  }
  .hs-error,
  .hs-success {
    color: red;
    margin: 0;
    padding: 0;
    font-size: 22px;
    margin-bottom: 1.25rem;
  }
  .hs-success {
    color: $hs-blue;
  }
  .hs-info {
    color: rgba(0, 0, 0, 0.5);
    text-align: left;
    margin-bottom: 1.25rem;
    display: block;
  }
}

footer {
  border-top: 1px solid $hs-grey-border1;
  overflow: hidden;
  a {
    text-decoration: none;
    color: $hs-black;
    &:hover,
    &:focus,
    &:active {
      color: $hs-blue;
      text-decoration: none;
    }
  }
}
.hs-footer-inner{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  margin: 1rem 0;
}

.hs-terms {
  li {
    display: table-cell;
    vertical-align: middle;
    font-size: 0.875rem;
    padding-right: 1.25rem;
    position: relative;

    &:last-child {
      margin-right: 0;
    }
  }
}
.hs-copyright {
  font-size: 0.875rem;
}
.hs-terms-area {
  text-align: left;
  overflow: hidden;
  padding: 3rem 0;
  .hs-caption1 {
    font-size: 36px;
    line-height: 36px;
    margin-bottom: 3rem;
    text-transform: none;
  }
  p {
    margin-bottom: 15px;
  }
  strong {
    margin-bottom: 10px;
    display: block;
  }
}
.hs-back-to-top{
  font-size: 1.5rem;
  font-weight: bolder;
  position: fixed;
  bottom: 79px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: $hs-blue;
  color: white;
  cursor: pointer;
  width:48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hs-common-area {
  overflow: hidden;
  padding: 3rem 0;
  text-align: center;
  border-bottom: 1px solid $hs-grey-border1;
  background-color: $hs-grey;
  .hs-common-content {
    text-align: center;
    font-size: 22px;
    line-height: 1.5;
    width: 921px;
    max-width: 100%;
    margin: 0 auto;
    &.hs-about-content{
      text-align: left;
    }
  }
  .hs-thank-you {
    margin: 10px 0 30px 0;
  }
  .hs-grey-content {
    font-size: 1.25rem;
    color: #111;
  }
}
.hs-contact-page-form {
  margin: 3rem 0;
}
.hs-contact-info-list {
  margin: 3rem 0;
  width: 300px;
  display: inline-block;
  text-align: left;
  li {
    position: relative;
    padding: 0 0 5px 3rem;
    margin-bottom: 30px;
    span {
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
    }
    .hs-c-mail {
      background: url(../images/hs-mail.svg) no-repeat;
      width: 66px;
      height: 50px;
      background-size: 23px 19px;
    }
    .hs-c-tel {
      background: url(../images/hs-telephone.svg) no-repeat;
      width: 62px;
      height: 54px;
      background-size: 25px 22px;
    }
    .hs-c-cell {
      background: url(../images/hs-cellphone.svg) no-repeat;
      width: 62px;
      height: 74px;
      background-size: 20px 24px;
    }
    .hs-c-add {
      background: url(../images/hs-location.svg) no-repeat;
      width: 40px;
      height: 52px;
      background-size: 18px 22px;
    }
  }
}
a {
  text-decoration: none;
  color: $hs-black;
  &:hover,
  &:focus,
  &:active {
    color: $hs-blue;
    text-decoration: underline;
  }
}
.hs-contact-person-image {
h5{
  margin: 5px 0;
  color: #231f20;
  line-height: 1;
  font-size: 0.75rem;
  text-transform: uppercase;
}
h3 {
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid $hs-grey;
  display: inline-block;
  font-size: 1rem;
  line-height: 1;
}
a.hs-att {
  background: #ccc;
  background: #ffff00;
  width: 24px;
  height: 24px;
  line-height: 0;
  display: inline-block;
}
a img {
  max-width: 24px;
  height: auto;
}
}

.hs-btn1 {
  background-color: transparent;
  text-decoration: none;
  color: $hs-blue;
  border: 3px solid $hs-blue;
  display: inline-block;
  padding: 1.5rem 6rem;
  font-size: 1.75rem;
  line-height: 1;
  font-family: $font-stack3;
  &:hover,
  &:focus,
  &:active {
    color: $hs-black;
    border-color: $hs-black;
    text-decoration: none;
  }
}
.hs-btn1-sm {
  padding: 10px 45px;
  font-size: 22px;
  line-height: 22px;
}

.hs-coding-clean-icon {
  background: url("../images/hs-coding-clean-icon.png");
  width: 100px;
  height: 38px;
  background-size: 100px 38px;
  float: right;
}

.hidden {
  display: none;
}
#hs-cover-canvas {
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  //background-image: url('');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  background-attachment: fixed;
}
.hs-testimonial-area {
  width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
  position: relative;
  padding: 3rem 0;
}
.hs-testimonial-detail {
  font-size: 18px;
  .hs-testimonial-left {
    width: 80%;
    float: left;
    text-align: left;
    .hs-testimonial-text {
      padding: 3rem 6rem 3rem 150px;
      position: relative;
      &:before {
        display: block;
        content: "\201C";
        font-size: 6rem;
        position: absolute;
        left: 75px;
        top: 25px;
        color: $hs-grey;
        font-family: $font-stack5;
      }
    }
  }
  .hs-testimonial-right {
    width: 20%;
    float: left;
    text-align: center;
    font-size: 0.875rem;
    padding-top: 3rem;
  }
}


.hs-404-content {
  min-height: 200px;
}
.slick-dots{
		display: none !important;
	}
.hs-clients-area,
.hs-web-technologies-types {
  .hs-slider {
    margin-top: 41px;
  }
  .slick-next {
    right: 0;
  }
  .slick-prev {
    left: 0;
  }
  .slick-slide {
    padding: 0 15px;
    img{
      width:100%;
      height: auto;
    }
  }

  .slick-list {
    margin: 0 2em;
  }

  .slick-prev:before,
  .slick-next:before,
  .slick-dots li button:before,
  .slick-dots li.slick-active button:before {
    color: $hs-blue;
  }
  .slick-dots li button:before,
  .slick-dots li.slick-active button:before {
    font-size: 1rem;
  }
  //.slick-list{padding-bottom: 3rem;}
  .slick-dots {
    bottom: -3rem;
  }
  .slider-controls {
    text-align: center;
    margin-top: 80px;
  }
  .play,
  .pause {
    font-size: 0;
    width: 25px;
    height: 25px;
    text-align: center;
    cursor: pointer;
    border: none;
    outline: none;
    background: $hs-blue;
    border-radius: 3px;
    opacity: 0.75;
    color: #fff;
    margin: 5px;
    padding: 0;
    &:hover {
      opacity: 1;
    }
    span {
      background-image: url("../images/icons/SVG/play.svg");
      width: 18px;
      height: 18px;
      display: inline-block;
    }
  }

  .pause span {
    background-image: url("../images/icons/SVG/pause.svg");
  }
}

//website types
.hs-sl-item {
  display: block;
  overflow: hidden;
  width: 100%;
  div {
    width: 100%;
    display: inline-block;
  }
}
.hs-sl-img {
  font-size: 0;
}

.hs-sl-txt {
  overflow: hidden;
  h3 {
    border: 1px dashed #b2beb5;
    padding: 5px;
    margin: 5px;
    margin-bottom: 2px;
    transform: skew(-20deg);

    span {
      transform: initial;
      transform: skew(20deg);
      display: inline-block;
      font-size: 1rem;
    }
  }
}
//Services
.hs-service-type{
  border-bottom: 1px solid $hs-grey-border1;
  padding: 7rem 0;
}
.hs-service{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  div{
    
      display: flex;
      flex-direction: column;
      flex-basis: 100%;
      flex: 1;
      &.hs-service-txt{
        justify-content: flex-end;
      }
    
    
  }
}
.hs-cta{
  padding: 7rem 0;
  text-align: center;
}
//Gallery
// .react-photo-album{

// }

img.hs-dark {
  display: none;
}
body.dark {
  a {
    color: #efefef;
    &:hover,
    &:focus,
    &:active {
      color: $hs-blue;
    }
  }
  .hs-btn1 {
    color: $hs-blue;
  }
  .hs-contact-icon:hover {
    background-position: -288px -96px;
  }
  .hs-3-leaf-clover-black-icon {
    background-position: -96px 0;
  }
  .hs-hvr-underline-from-right::before {
    background-color: rgba(239, 239, 239, 0.3);
  }
  .hs-step-icon {
    fill: rgba($color: #ffffff, $alpha: 0.5);
  }
  img.hs-light {
    display: none;
  }
  img.hs-dark {
    display: block;
  }
  .hs-main-area,
  .hs-common-area {
    background-color: #121212;
  }
  .hs-btn1:hover,
  .hs-btn1:focus,
  .hs-btn1:active {
    color: #eaeaea;
    border-color: #eaeaea;
  }
  footer a {
    color: #e5e5e5;
    &:hover,
    &:focus,
    &:active {
      color: $hs-blue;
    }
  }
  header,
  .hs-main-area,
  .hs-clients-area .hs-border-left,
  .hs-common-contact-area .hs-border-left,
  .hs-clients-area,
  .hs-common-contact-area,
  footer,
  .hs-common-area,
  .hs-web-technologies-types .hs-border-left,
  .hs-web-technologies-types,
  .hs-common-contact-area .hs-half-block:first-child,
  .hs-testimonials-area {
    border-color: rgba(239, 239, 239, 0.3);
  }
  .hs-gallery-separator {
    background-color: rgba(239, 239, 239, 0.3);
  }
  .hs-contact-person-image h3 {
    border-color: rgba(239, 239, 239, 0.3);
  }
  .hs-contact-person-image h5 {
    color:#888;
  }

  .hs-form input,
  .hs-form textarea {
    background-color: #f8f8f8;
    background-color: #1f2023;
    color: #efefef;
  }
  .hs-form .hs-info, .hs-form .hs-select{
    color: rgba(239, 239, 239, 0.5);
  }
  .hs-form .hs-error,
  .hs-form .hs-success,
  .hs-field span {
    color: pink;
  }
  .hs-form input.has-error,
  .hs-form textarea.has-error {
    border-color: pink;
  }

  .hs-coding-clean-icon {
    background: url("../images/Untitled-1.png");
    width: 100px;
    height: 38px;
    background-size: 100px 38px;
  }

  .hs-home-icon.hs-f-home {
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7%)
      hue-rotate(188deg) brightness(104%) contrast(101%);
    &:hover {
      filter: invert(72%) sepia(32%) saturate(6093%) hue-rotate(165deg)
        brightness(95%) contrast(87%);
    }
  }
}
.yarl__slide_title_container,.yarl__slide_captions_container{
  background: none !important;
}
//Media Queries
@import "partials/media-queries";