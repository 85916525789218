$font-stack1: Helvetica, sans-serif;
$font-stack2: 'Palatino Linotype', 'Book Antiqua', Palatino, serif;
$font-stack3:'Times New Roman', Times, serif;
$font-stack4: Georgia, serif;
$font-stack5: Arial, Helvetica, sans-serif;

$primary-color: #333;
$hs-blue:#2aace3;
$hs-blue: #3dacde;
$hs-black:#000;
$hs-grey:#f5f5f5;
$hs-grey-border1:#ededed;
$hs-grey-border2:#838385;
$heading-font1:'Impact', 'Haettenschweiler', 'Franklin Gothic Bold' , 'Charcoal', 'Helvetica Inserat', 'Bitstream Vera Sans Bold', 'Arial Black','sans serif';
$heading-font2:'Arial Black', 'Arial Bold', 'Gadget', sans-serif;
$heading-font3:Arial, 'Helvetica Neue', Helvetica, sans-serif;

$background-color:#ffffff;
$foreground-color: #292929;
$background-color-dk: #1f2023;
$foreground-color-dk:#efefef;
$image-background-color-dk:#efefef;

$image-filter:grayscale(0) opacity(100%);
$image-filter-dk:grayscale(50%) opacity(90%);