//Media Queries




//1366x768, 1600x900, 1920x1080, 1920x1200, 2560x1440, 2560x1600, 4KUHD (3840x2160), 4K(4096x2160)
//Minimum Width
@media only screen and (min-width:320px) {}

@media only screen and (min-width:480px) {}

@media only screen and (min-width:640px) {}

@media only screen and (min-width:768px) {}

@media only screen and (min-width:1024px) {}

@media only screen and (min-width:1280px) {}

@media only screen and (min-width:1536px) {}

@media only screen and (min-width:1280px) {}

@media only screen and (min-width:1600px) {}
@media only screen and (min-width:1728px) {
	.slick-dots{
		display: block !important;
	}
}



//Max Width
@media only screen and (max-width:1600px) {
	/*
	.hs-slider .slick-dots {
		display: none !important;
	}
	.hs-clients-area .slider-controls,
	.hs-web-technologies-types .slider-controls {
		margin-top: 20px;
	}
	*/
}
@media only screen and (max-width:1410px) {
	.hs-testimonials-area blockquote{
		max-width: 70%;
	}

}
@media only screen and (max-width:1280px) {
	.hs-half-block {
		width: 100%;
		border-left: 0;
		border-right:0;
		float: none;
		display: block;
		border-bottom: 1px solid #f5f5f5;
		
	}
	.hs-common-contact-area,.hs-web-technologies-types {
		.hs-wrapper{
			width:100%;
			.hs-half-block{
				&:last-child{
					border-bottom:0;
				}
				.hs-half-block-inner{
				width:95%;
				margin: 0 auto;
				}
			}
		}
	}

	.hs-half-block {
		.hs-caption1 {
			font-size: 4.5rem;
			line-height: 5rem;
		}

		.hs-blue-separator {
			width: 130px;
			margin: 3rem 0;
		}
	}

	.hs-half-block-inner {
		padding: 7rem 0;
	}


}

@media only screen and (max-width:1279px) {
	.hs-keys-services .hs-key-service {
		flex-wrap: wrap-reverse;
	}

	.hs-key,
	.hs-service {
		flex: 0 0 100%;
	}
	.hs-key-txt{
		text-align: left;
	}

	.hs-keys-services .hs-service {
		flex-direction: row;

		span {
			margin-left: 0;
			margin-right: 1.25rem;
		}
	}
}

@media only screen and (max-width:1200px) {
	.hs-caption1 {
		font-size: 4rem;
		line-height: 4.25rem;
	}

	.hs-caption2 {
		font-size: 3.5rem;
		line-height: 3.75rem;
	}

	.hs-btn1 {
		padding: 1.25rem 4rem;
		font-size: 1.5rem;
	}
}

@media only screen and (max-width:1150px) {

	//.hs-keys,.hs-services{width:100%; float:none;}
	//.hs-keys{margin-bottom:50px;}
	.hs-testimonial-detail {
		.hs-testimonial-left {
			width: 60%;
		}

		.hs-testimonial-right {
			width: 40%;
		}
	}
}

@media only screen and (max-width:1024px) {

	.hs-caption1,
	.hs-half-block .hs-caption1 {
		font-size: 3.5rem;
		line-height: 3.75rem;
	}

	.hs-caption2 {
		font-size: 3rem;
		line-height: 3.25rem;
	}
	.hs-testimonials-area blockquote {
		max-width: 80%;
	  }
	.hs-testimonial-detail {
		.hs-testimonial-left {
			width: 100%;

			.hs-testimonial-text {
				padding: 25px 0 25px 50px;

				&:before {
					left: 0;
					top: 0;
					font-size: 80px;
				}
			}
		}

		.hs-testimonial-right {
			width: 100%;
			padding-top: 0;

			.hs-testimonial-right-inner {
				max-width: 200px;
				margin: 0 auto;
			}
		}
	}
}

@media only screen and (max-width:980px) {
	.hs-clients-area .hs-half-block .hs-clients-list li {
		width: 100%;
	}
	.hs-testimonials-area blockquote {
		max-width: 90%;
	}

}

@media only screen and (max-width:815px) {

	.hs-caption1,
	.hs-half-block .hs-caption1 {
		font-size: 3rem;
		line-height: 3.25rem;
	}

	.hs-caption2 {
		font-size: 2.5rem;
		line-height: 2.75rem;
	}

	.hs-steps li {
		font-size: 2rem;
	}

	.hs-keys-services .hs-key-service {
		font-size: 1.5rem;
	}
}

@media only screen and (max-width:768px) {
	.hs-cover-area .hs-cover-top {
		margin: 150px 0 100px;
	}

	.hs-main-area,
	.hs-half-block-inner,
	.hs-clients-area,
	.hs-testimonials-area,
	.hs-form-area {
		padding: 3.5rem 0;
	}

	.hs-steps {
		margin-bottom: 3rem;
	}

	.hs-caption1,
	.hs-half-block .hs-caption1 {
		font-size: 2.5rem;
		line-height: 2.75rem;
	}

	.hs-caption2 {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	.hs-steps li {
		font-size: 1.75rem;
	}

	.hs-keys-services .hs-key-service {
		font-size: 1.25rem;
	}

	.hs-btn1 {
		padding: 1rem 3.5rem;
		font-size: 1.25rem;
	}
	.hs-form-area p,blockquote{
		font-size: 1.5rem;
	}
	.hs-testimonials-area blockquote {
		max-width: 100%;
	}
	.hs-footer-inner {
		display: block;
		flex-flow: inherit;
		justify-content: inherit;
		align-items: inherit;
		text-align: center;
		width: 100%;
	}

	.hs-terms,
	.hs-coding-clean-icon {
		display: inline-flex;
		float: none;
	}

	.hs-coding-clean-icon {
		margin-top: 1rem;

	}

}


@media only screen and (max-width:640px) {}

@media only screen and (max-width:600px) {
	.hs-form .hs-field-half {
		width: 100%;
	}

	.hs-form .hs-field-half-right {
		margin-left: 0;
	}
}

@media only screen and (max-width:585px) {
	.hs-cover-area .hs-cover-top{
		margin: 5rem 0 3.5rem 0;
	}
	.hs-cover-area .hs-caption2{
		margin-bottom: 3.5rem;
	}
	.hs-steps li{
		font-size: 1.25rem;
	}
	.hs-steps .hs-step-cnt {
		font-size: 1rem;
	}
	.hs-caption1,
	.hs-half-block .hs-caption1 {
		font-size: 2rem;
		line-height: 2.25rem;
	}

	.hs-caption2 {
		font-size: 1.5rem;
		line-height: 1.75rem;
	}
	.hs-keys-services .hs-key-service{
		font-size: 1rem;
	}
	.hs-keys-services .hs-service span:not(.hs-key-txt),.hs-keys-services .hs-key span:not(.hs-key-txt){
		transform: scale(0.7);
		margin-right: 0.625rem;
	}
	.hs-terms {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;

		li {
			&:last-child {
				flex-basis: 100%;
				max-width: 100%;
				padding-right: 0;
				margin-top: 1rem;
			}
		}
	}
	.hs-form-area p{
		font-size: 1.25rem;
	}
	blockquote{
		font-size: 1.25rem;
	}

}



@media only screen and (max-width:480px) {
	body {
		min-width: 320px;
	}

	.hs-header-inner {
		padding: 1rem 0;
	}

	.hs-logo a {
		width: 200px;
	}

	.hs-caption1, .hs-half-block .hs-caption1 {
		font-size: 1.25rem;
		line-height: 1.5rem;
	}
    .hs-caption2 {
		font-size: 1rem;
		line-height: 1.25rem;
	}
	.hs-blue-separator,.hs-half-block .hs-blue-separator{
		width:65px;
		margin: 2rem 0;
	}
	.hs-steps li {
		font-size: 1rem;
	  }
	.hs-steps .hs-step-cnt {
		font-size: 0.875rem;
	}

	  .hs-keys-services .hs-key, .hs-keys-services .hs-service{
		flex-flow: row nowrap;
		display: inline-flex;
	  }

	.hs-btn1 {
		padding: 0.625rem 2.25rem;
		font-size: 1.25rem;
	}

	.hs-testimonial-detail {
		.hs-testimonial-left {
			width: 100%;

			.hs-testimonial-text {
				padding: 20px 0 20px 30px;

				&:before {
					left: 0;
					top: 0;
					font-size: 60px;
				}
			}
		}

	}
}

@media only screen and (max-width:375px) {
	.hs-logo a {
		width: 150px;
	}
}

@media only screen and (max-width:340px) {
	.hs-terms {
		li {
			flex-basis: 25%;
			width: 25%;

			&:nth-last-child(2),
			&:nth-last-child(3) {
				flex-basis: 25%;
				width: 25%;
				margin-top: 1rem;
			}
		}
	}

}


//Minimum Height
@media only screen and (min-height:1080px) {
	.hs-cover-area .hs-cover-top {
		margin: 15.625rem 0 6.25rem;
	}
}

//Maximum Height

@media only screen and (max-height:650px) {
	.hs-cover-area .hs-cover-top {
		margin: 9.375rem 0 6.25rem;
	}
}

@media only screen and (max-height:600px) {
	.hs-cover-area .hs-cover-top {
		margin: 6.25rem 0 6.25rem;
	}
}

@media only screen and (max-height:500px) {
	.hs-cover-area .hs-cover-top {
		margin: 3.125rem 0 3.125rem;
	}

	.hs-blue-separator,
	.hs-clients-area .hs-half-block .hs-blue-separator,
	.hs-common-content-area .hs-half-block .hs-blue-separator {
		margin: 1.25rem 0;
	}

	.hs-cover-area .hs-caption2 {
		margin-bottom: 50px;
	}

	.hs-main-area,
	.hs-form-area,
	.hs-half-block-inner {
		padding: 3.125rem 0;
	}

	.hs-steps {
		margin-bottom: 3.125rem;
	}

	.hs-404-content {
		min-height: inherit;
	}
}