.react-toggle {
    touch-action: pan-x;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;

  }
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 64px;
    height: 34px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    background-color: $hs-blue;
    background-color: #cecece;
    //background-color: #73C0FC;
    //border:1px solid $hs-blue;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  // .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
  //   background-color: #000000;
  //   background-color:#333333;
  // }
  
  .react-toggle--checked .react-toggle-track {
    background-color: #19AB27;
    background-color: $hs-blue;
    background-color: #3a3a3a;
    //background-color: #183153;
    //box-shadow: 0 0 1px #cecece;
  }
  
  // .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  //   background-color: #128D15;
  //   background-color: #333;
  //   background-color: transparent;
  // }
  
  .react-toggle-track-check {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 4px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
    z-index: 1;
    img{
      animation: inherit;
    }
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
    img{
      animation:  tilt 5s linear infinite;
    }
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 4px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
    z-index: 1;
    img{
      animation:  rotate 15s linear infinite;
    }
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 1;
    img{
      animation: inherit;
    }
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #e8e8e8;
    background-color: #f2f2f2;
    background-color: #73C0FC;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 2px;
    right:inherit;
    //border-color: #19AB27;
    background-color: rgb(33, 33, 33);
    background-color: #183153;
  }
  
//   .react-toggle--focus .react-toggle-thumb {
//     -webkit-box-shadow: 0px 0px 3px 2px #0099E0;
//     -moz-box-shadow: 0px 0px 3px 2px #0099E0;
//     box-shadow: 0px 0px 2px 3px #0099E0;
//   }
  
//   .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
//     -webkit-box-shadow: 0px 0px 5px 5px #0099E0;
//     -moz-box-shadow: 0px 0px 5px 5px #0099E0;
//     box-shadow: 0px 0px 5px 5px #0099E0;
//   }
  

@keyframes rotate {
 
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes tilt {
 
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(10deg);
  }

  100% {
    transform: rotate(0deg);
  }
}